<template>
  <div class="login-section section" v-if="!$starting && $locale">
    <div class="login-section-form">
      <div class="login-section-form-container">
        <div class="login-section-form-container-inner">
          <div class="login-section-picture" dcolor>
            <router-link to="/">
              <figure class="logo flex-center">
                <iconic name="mf_logo_shadow" :options="{ original: true }" />
              </figure>
            </router-link>
          </div>
          <div class="login-section-tabs">
            <div class="login-section-tabs-buttons">
              <router-link class="tab-btn" to="/signup" ellipsis>{{ $locale["input-signup-btn"] }}</router-link>
              <router-link class="tab-btn" to="/login" ellipsis>{{ $locale["login-btn"] }}</router-link>
            </div>
          </div>
          <div class="login-section-data" :route="this.$route.path">
            <router-view></router-view>
          </div>
          <div class="login-section-signin-with">
            <userSigninWith />
          </div>
          <div class="login-section-footer">
            <LoginFooter />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userSigninWith from "./userSigninWith.vue";
import LoginFooter from "../home/LoginFooter";
export default {
  components: { userSigninWith, LoginFooter },
  metaInfo: function() {
    return {
      title: `Login - My Fans 1A`,
    };
  },
  mounted: function() {
    this.scrollIntoView("#app");
  },
};
</script>

<style lang="scss">
.login-section {
  display: flex;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  .logo {
    svg,
    img {
      width: 180px;
      height: auto;
    }
  }
  &-form {
    width: 100%;
    max-width: 380px;
    padding: $mpadding;
    margin: 0 auto;
    &-title {
      padding: $mpadding 0;
    }
    &-container {
      position: relative;
      padding: $mpadding;
      background: $white;
      border-radius: $mradius/2;
      box-shadow: $dshadow;
      display: flex;
      align-items: center;
      overflow: hidden;
      &-inner {
        position: relative;
        margin: auto;
        z-index: 2;
        width: 100%;
      }
    }
    &-title {
      font-size: 32px;
    }
  }
  &-tabs {
    text-align: right;
    margin-top: $mpadding;
    position: relative;
    z-index: 2;
    .tab-btn {
      display: inline-block;
      padding: $mpadding;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
      position: relative;
      border-top-left-radius: $mpadding;
      border-top-right-radius: $mpadding;
      padding-bottom: $mpadding * 1.5;
      margin-bottom: -$mpadding;
      max-width: 50%;
      &:first-child {
        box-shadow: 1px 0 7px 0px rgba(0, 0, 0, 0.25);
      }
      &.router-link-exact-active {
        color: $primary_color;
        background: #fff;
      }
    }
    &-buttons {
      white-space: nowrap;
    }
  }
  &-data {
    padding: $mpadding;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: $mpadding;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: $mpadding * 1.75;
      top: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 2;
      border-radius: $mpadding;
    }
    &[route="/signup"] {
      border-radius: $mpadding $mpadding $mpadding $mpadding;
    }
  }
  &-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $mpadding/2 0 0 $mpadding/2;
    a.link-btn {
      display: flex;
      align-items: center;
      font-size: 80%;
      cursor: pointer;
      margin-top: $mpadding;
      input + *:not(:empty) {
        margin-left: $mpadding/2;
      }
      label {
        display: flex;
        align-items: center;
        padding: 0;
      }
      .mycheck {
        width: 20px;
        height: 20px;
      }
      &.underline {
        border-bottom: solid 1px;
      }
    }
  }
  &-signin-with {
    margin-top: $mpadding;
  }
  @media (min-width: $tablet_width) {
  }
}
</style>
