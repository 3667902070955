<template>
  <a :class="`social-button-2 ${icon}`" v-if="label && icon" @click="$emit('click')">
    <span :class="`social-button-2-icon ${icon}`"><iconic :name="icon" /></span>
    <label v-html="label"></label>
  </a>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
  },
};
</script>

<style lang="scss">
.social-button-2 {
  position: relative;
  cursor: pointer;
  @include Flex(row, center, center);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 2pc;
  color: #fff;
  min-height: 45px;
  user-select: none;
  &.facebook {
    background: #1556c1;
  }
  &.twitter {
    background: #1da1f2;
  }
  &.google {
    background: #4285f4;
  }
  &.apple {
    background: #666666;
  }
  &-icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    @include Flex(inherit, center, center);
    font-size: 120%;
    position: absolute;
    left: 0;
    .iconic {
      @include Flex(center, center, center);
    }
  }
  > * {
    pointer-events: none;
  }
}
</style>
