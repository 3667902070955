<template>
  <footer id="footer" class="footer section-footer" v-if="$locale">
    <div class="footer-content">
      <div class="footer-contact">
        <div class="footer-copyr" v-html="`&copy; ${$global.getCurrentYear()} ${$locale.title}`"></div>
        <div class="footer-social">
          <div class="footer-social-button">
            <a :href="$locale['instagram-link']" target="_blank"><iconic name="instagram" /></a>
          </div>
          <div class="footer-social-button">
            <a :href="$locale['twitter-link']" target="_blank"><iconic name="twitter" /></a>
          </div>
          <LanguageButton _for="login_footer" />
        </div>
      </div>
      <div class="footer-message">
        <small v-html="`${$locale['footer_message']}`"></small>
      </div>
      <LandingFooterLegal _for="login" />
    </div>
  </footer>
</template>

<script>
export default {
  components: {
    LandingFooterLegal: () => import("../landing/LandingFooterLegal.vue"),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  &-content {
    padding: $mpadding/2 0 0 0;
    margin: $mpadding/2 0 0 0;
    border-top: solid 1px $border_color;
  }
  &-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $mpadding/2;
  }
  &-copyr {
    font-size: smaller;
  }
  &-social {
    display: flex;
    gap: $mpadding;
  }
  &-message {
    color: $mine_shaft;
  }
  .language-button-flag {
    width: 1em !important;
    height: 1em !important;
  }
}
</style>
