<template>
  <div class="user-signin-with" v-if="$locale">
    <div class="user-signin-with-art"><figure>o</figure></div>
    <div>{{ $locale["signin-with"] }}</div>
    <div class="third-platforms">
      <div class="third-platforms-container">
        <SocialNetworkButton
          :label="`${$locale['continue']} <span lower>${$locale['with']}</span> Facebook`"
          icon="facebook"
          @click="logInWithFacebook"
          dactive
        />
        <!-- <SocialNetworkButton label="Twitter" icon="twitter" @click="oauthLogin('twitter')" dactive /> -->
        <SocialNetworkButton
          :label="`${$locale['continue']} <span lower>${$locale['with']}</span> Google`"
          icon="google"
          @click="logInWithGoogle"
          dactive
        />
        <!-- <SocialNetworkButton label="Apple" icon="apple" @click="oauthLogin('apple')" dactive /> -->
      </div>
    </div>
  </div>
</template>

<script>
import SocialNetworkButton from "../buttons/SocialNetworkButton2.vue";
export default {
  components: { SocialNetworkButton },
  methods: {
    oauthLogin: async function(options) {
      const provider = typeof options === "string" ? options : options?.provider;
      this.ialert.go({ type: "loader" });
      this.oauth.login(options).then((response) => {
        if (response?.error) {
          this.ialert.go({ title: `<span capitalize>${provider}</span> error`, message: response?.error, unique: true });
        } else {
          response.provider = provider;
          this.sendData(response);
        }
      });
    },
    logInWithGoogle: async function() {
      this.ialert.go({ type: "loader" });
      this.igoogle.login({}).then((response) => {
        if (response?.error) {
          this.ialert.go({ title: "Google error", message: response?.details, unique: true });
        } else {
          response.provider = "google";
          this.sendData(response);
        }
      });
    },
    logInWithFacebook: async function() {
      this.ialert.go({ type: "loader" });
      this.ifacebook.login({}).then((response) => {
        if (response?.authResponse) {
          response.provider = "facebook";
          this.sendData(response);
        } else {
          this.ialert.go({ title: "Facebook error", message: `Facebook ${response?.status}`, unique: true });
        }
      });
    },
    sendData: function(data) {
      if (data) {
        const _data = data;
        const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "user/oauth", _data);
        sedData.then((response) => this.sendSuccess(response));
        sedData.catch((response) => this.sendError(response));
      }
    },
    sendSuccess: async function(response) {
      if (response?.data?.success) {
        await this.$store.dispatch("updateUser", response.data);
        await this.$store.dispatch("getProfile");
        this.$store.dispatch("loginSuccessRedirect");
        this.ialert.close();
      } else {
        this.ialert.go({ message: response?.data?.message, unique: true, title: this.$locale["error-auth-title"] });
      }
    },
    sendError: function(response) {
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
  },
  mounted: async function() {
    this.oauth.init();
    this.igoogle.init();
    this.ifacebook.init();
  },
};
</script>

<style lang="scss">
.user-signin-with {
  &-art {
    display: flex;
    justify-content: center;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      top: 50%;
      border-top: solid 1px $border_color;
    }
    figure {
      position: relative;
      width: $mpadding * 2;
      height: $mpadding * 2;
      line-height: $mpadding * 2;
      background: white;
      border-radius: 50%;
    }
  }
  .third-platforms {
    padding: $mpadding;
    &-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: $mpadding;
    }
    &-button.apple,
    &-button.twitter {
      pointer-events: none;
      filter: grayscale(1);
      opacity: 0.6;
    }
  }
}
</style>
